import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BasicStats from './basic-stats.component'
import FoodContainer from './fullscreen/food-container.component'
import PSDFullScreen from './fullscreen/PSD-fullscreen.component'
import Road from './fullscreen/road-fullscreen.component'
import Header from './header.component'
import InvestProjects from './invest-projects.component'
import MediaMonitoring from './media-monitoring.component'
import PopulationDemand from './population-demand.component'
import PopulationDynamic from './population-dynamic.component'
import PSD from './PSD.component'
import ThemeContext, { Theme } from '../../contexts/theme.context'
import { Filters, RightMenuContainer } from './index.styles'
import {
  IBudgetInvestItem,
  IMarker,
  IPopulationDemand,
  IPopulationDetails,
  IPopulationDynamic,
  IProductSufficiencyItem,
  IPSDItem,
  IRoadShare,
  ITaxStat,
} from '../../interfaces/snp.interface'
import { navLinks } from '../../constants/navlinks.constant'
import { getGeneralInfo } from '../../requests/snp.request'
import { getLang, getSelfSufficiencyPercentage, isRegion } from '../../utils/helpers.utils'
import { Text } from '../text.component'
import InvestProjectsFullScreen from './fullscreen/invest-projects-fullscreen.component'
import Screening from './tabs/screening.component'
import SER from './fullscreen/ser.component'
import DistrictIncomeFullScreen from './fullscreen/district-income-fullscreen.component'
import InfrastuctureFullScreen from './fullscreen/infrastructure-fullscreen.component'
import { getMMData } from '../../requests/kato.request'
import PupulationDynamicFullScreen from './fullscreen/population-dynamic-fullscreen.component'
import TaxComponent from './tax.component'
import { DeleteX, DeleteXWhite } from '../../assets/icons'
import { cityObject } from '../../constants/snp.constant'
import { useSharedContext } from '../../contexts/shared.context'

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string | null) => void;
  selectedMarker: IMarker | null;
  selectedRegion: any;
  toggleShowLeftMenu: () => void;
  showLeftMenu: boolean;
  setShowLeftMenu: (show: boolean) => void;
}

const RightMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedMarker, selectedRegion, toggleShowLeftMenu, showLeftMenu, setShowLeftMenu }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const { selectedDataYear, updateSelectedDataYear } = useSharedContext();

  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { link, kato } = useParams()
  const [basicStats, setBasicStats] = useState<any>(null)
  const [additionalStats, setAdditionalStats] = useState<any>([])
  const [investProjects, setInvestProjects] = useState<any>([])
  const [populationDemand, setPopulationDemand] = useState<IPopulationDemand[]>([])
  const [psdData, setPsdData] = useState<IPSDItem[]>([])
  const gridRef = useRef<HTMLDivElement>(null);
  const [productSelfSufficiency, setProductSelfSufficiency] = useState<IProductSufficiencyItem[]>([]);
  const [budgetData, setBudgetData] = useState<IBudgetInvestItem[]>([]);
  const [populationDynamicStats, setPopulationDynamicStats] = useState<IPopulationDynamic[]>([]);
  const [roadData, setRoadData] = useState<IRoadShare>({} as IRoadShare);
  const [mmData, setMMData] = useState<any>(null);
  const [populationDetails, setPopulationDetails] = useState<IPopulationDetails>();
  const [taxStats, setTaxStats] = useState<ITaxStat[]>([]);

  const [fullScreen, setFullScreen] = useState<{
    isOpen: boolean;
    type: string | null;
  }>({
    isOpen: false,
    type: null,
  });

  const closeModal = () => {
    setIsOpen(false);
    setShowLeftMenu(true);
  };

  const handleFullScreen = (obj: any) => {
    setFullScreen(obj);
  };

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (selectedTab) {
      if (selectedTab !== 'snp') {
        kato ? navigate(`/${kato}/${selectedTab}`) : navigate(`/${selectedTab}`)
      } else {
        !selectedLink && setSelectedLink(navLinks[0].name);
        kato ? navigate(`/${kato}/snp`) : navigate('/snp')
      }
      setFullScreen({ isOpen: false, type: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, setSelectedLink])

  useEffect(() => {
    if (kato) {
      if (!selectedTab) {
        setSelectedTab('snp')
      }
    }
  }, [selectedLink, kato])

  useEffect(() => {
    link && setSelectedTab(link);
  }, [link])

  useEffect(() => {
    if (kato || selectedMarker) {
      setIsOpen(true);
      const val = selectedMarker ? selectedMarker.kato : kato;
      if (val) {
        getMMData(val).then((res) => {
          setMMData(res);
        })

        getGeneralInfo(+val, selectedDataYear).then((res) => {
          if (res.basicStats?.every((stat: any) => stat.previousYearVal === null || stat.currentYearVal === null || stat.increaseVal === null)) {
            setBasicStats([])
          } else {
            const grouped = res.basicStats?.filter((item: any) => item.type !== 'ADDITIONAL_STAT').reduce((acc: any, stat: any) => {
              if (!acc[stat.type]) {
                acc[stat.type] = [];
              }
              acc[stat.type].push(stat);
              return acc;
            }, {});

            const additionalStats = res.basicStats?.filter((item: any) => item.type === 'ADDITIONAL_STAT');

            const groupedAdditionalStats = additionalStats?.reduce((acc: any, stat: any) => {
              if (!acc[stat.additionalInfo?.[`type${getLang()}`]]) {
                acc[stat.additionalInfo?.[`type${getLang()}`]] = [];
              }
              acc[stat.additionalInfo?.[`type${getLang()}`]].push(stat);
              return acc;
            }, {})
            setBasicStats(grouped);
            setAdditionalStats(groupedAdditionalStats);
          }
          const sortedInvestProjects = res.budgetInvestmentProjectStats
            .sort((a: any, b: any) => b.totalPrice - a.totalPrice)
            .map((item: any) => ({ ...item, realizationDate: item.realizationDate ? item.realizationDate.split('T')[0].split('-').reverse().join('.') : '' }));
          setPopulationDemand(res.populationDemandStatsCounts)
          setInvestProjects(sortedInvestProjects)
          setPopulationDynamicStats(res.populationIncreaseDynamicStats.sort((a, b) => a.year - b.year))
          setPsdData(res.projectEstimateDocumentation)
          setProductSelfSufficiency(
            res.productSelfSufficiencyStats.map((item: IProductSufficiencyItem) => ({
              ...item,
              selfSufficiencyPercentage: +getSelfSufficiencyPercentage(item)
            })).sort((a, b) => b.selfSufficiencyPercentage - a.selfSufficiencyPercentage))
          setBudgetData(res.budgetInvestment)
          setRoadData(res.totalRoadShareStats)
          setPopulationDetails(res.populationDetailsStats)
          setTaxStats(res.taxStats)
        })
      }

      handleFullScreen({ setOpen: false, type: null });
    }
  }, [kato, selectedMarker, selectedDataYear])

  return (
    <>
      {isOpen && kato && <RightMenuContainer ref={containerRef} isFull={!showLeftMenu} style={{ minHeight: fullScreen.isOpen ? 'calc(100vh - 1rem)' : 'min-content' }}>
        <Header showLeftMenu={showLeftMenu} toggleShowLeftMenu={toggleShowLeftMenu} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={() => closeModal()} />
        <Filters>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <Text fontWeight={500} color='var(--text-color)' fontSize="1.125rem">
              {
                isRegion(+kato) || (selectedMarker && +selectedMarker?.code === +cityObject.kato)
                  ? <>
                    {selectedRegion?.[`name${getLang()}`].toUpperCase().trim()}
                  </>
                  : <>
                    {selectedMarker?.nameRu.toUpperCase().trim()}
                  </>
              }
              {fullScreen.isOpen &&
                <>
                  {` - ${t(`new-fields.${fullScreen.type}`).toUpperCase()}`}
                </>
              }
            </Text>

            <select
              name="year"
              id="year"
              value={selectedDataYear}
              onChange={(e) => updateSelectedDataYear(Number(e.target.value))}
            >
              {[2024].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          {
            fullScreen.isOpen &&
            <div className='close-icon' onClick={() => handleFullScreen({ isOpen: false, type: null })}>
              {theme === Theme.LIGHT
                ? <DeleteX width={12} height={12} />
                : <DeleteXWhite width={12} height={12} />}
            </div>

          }

        </Filters>
        {
          fullScreen.isOpen ? <>
            {fullScreen.type === 'FOOD' && productSelfSufficiency && productSelfSufficiency.length > 0 && <FoodContainer data={productSelfSufficiency} />}
            {psdData && fullScreen.type === 'PSD' && <PSDFullScreen kato={selectedRegion.kato} />}
            {fullScreen.type === 'ROAD' && roadData && <Road data={roadData} />}
            {fullScreen.type === 'InvestProjects' && <InvestProjectsFullScreen scrollToTop={scrollToTop} data={investProjects} />}
            {fullScreen.type === 'SER' && basicStats && kato && <SER kato={kato} DATA={basicStats} />}
            {fullScreen.type === 'DistrictIncome' && <DistrictIncomeFullScreen kato={kato} />}
            {fullScreen.type === 'INFRASTRUCTURE' && <InfrastuctureFullScreen />}
            {fullScreen.type === 'population-dynamic' && populationDetails && <PupulationDynamicFullScreen populationDetails={populationDetails} populationDynamic={populationDynamicStats} />}
          </>
            : selectedTab === 'snp' ? <div className="grid" ref={gridRef}>
              {
                <>
                  {isRegion(+kato) ? <>
                    {basicStats && additionalStats && <BasicStats kato={+kato} filtered={['SER']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}

                    {psdData && <PSD show={showLeftMenu} handleFullScreen={handleFullScreen} data={psdData} />}
                    {
                      populationDynamicStats && <PopulationDynamic show={showLeftMenu} handleFullScreen={handleFullScreen} chartData={populationDynamicStats} />
                    }

                    {budgetData && <InvestProjects budgetData={budgetData} handleFullScreen={handleFullScreen} />}
                    {basicStats && additionalStats && <BasicStats filtered={['FOOD']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {basicStats && <BasicStats filtered={['INFRASTRUCTURE']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}

                    {basicStats && <BasicStats filtered={['ROAD']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} totalRoadShareStats={roadData} />}
                    {taxStats && <TaxComponent handleFullScreen={handleFullScreen} data={taxStats} kato={+kato} />}
                    {populationDemand && populationDemand.length > 0 && selectedRegion && <PopulationDemand kato={selectedRegion?.kato} data={populationDemand} />}
                    {<MediaMonitoring list={mmData} regionName={selectedRegion?.nameRu} />}

                  </>
                    :
                    <>
                      {basicStats && additionalStats && <BasicStats filtered={['FOOD']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                      {populationDemand && populationDemand.length > 0 && selectedRegion && <PopulationDemand kato={selectedRegion?.kato} data={populationDemand} />}
                      {
                        populationDynamicStats && <PopulationDynamic show={showLeftMenu} handleFullScreen={handleFullScreen} chartData={populationDynamicStats} />
                      }
                      {budgetData && <InvestProjects budgetData={budgetData} handleFullScreen={handleFullScreen} />}
                      {basicStats && <BasicStats filtered={['INFRASTRUCTURE']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                      {taxStats && <TaxComponent handleFullScreen={handleFullScreen} data={taxStats} kato={+kato} />}
                    </>
                  }
                </>
              }
            </div>
              : <Screening isFull={!showLeftMenu} />

        }
      </RightMenuContainer >
      }
    </>
  )
}

export default RightMenu