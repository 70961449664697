import React, { createContext, useState, useContext, ReactNode } from 'react';

interface SharedContextProps {
  selectedDataYear: number;
  setSelectedDataYear: (year: number) => void;
  updateSelectedDataYear: (year: number) => void;
}

const SharedContext = createContext<SharedContextProps | undefined>(undefined);

export const SharedProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDataYear, setSelectedDataYear] = useState<number>(2024);

  const updateSelectedDataYear = (year: number) => {
    setSelectedDataYear(year);
  };

  return (
    <SharedContext.Provider value={{ selectedDataYear, setSelectedDataYear, updateSelectedDataYear }}>
      {children}
    </SharedContext.Provider>
  );
};

export const useSharedContext = (): SharedContextProps => {
  const context = useContext(SharedContext);
  if (!context) {
    throw new Error('useSharedContext must be used within a SharedProvider');
  }
  return context;
};